@tailwind base;
@tailwind components;
@tailwind utilities;

.description {
    background-color: none;
    z-index: 3;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: 700;
}

.stockImg {
    width: 100%;
}

.shadow-white {
    background-color: white;
    opacity: 0.7;
    z-index: 2;
    overflow: hidden;
}

.portfolioOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0,0,0,0.5);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.gray-text-shadow {
    text-shadow: 2px 2px rgba(31,41,55);
}